<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <span>代收费后台管理</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>

    <el-container>
      <el-aside width="200px">
        <el-menu
          :default-active="this.$router.path"
          router
          :default-openeds="['1']"
        >
          <el-submenu index="1">
            <template slot="title" index="1">
              <i class="el-icon-location"></i>
              <span slot="title">我的菜单</span>
            </template>
            <el-menu-item index="/list">学生信息</el-menu-item>
            <el-menu-item index="/export">收费统计</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: #e5e9f2;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #e6a23c;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #e5e9f2;
  .el-menu {
    border-right: none;
    background-color: e5e9f2;
  }
}

.el-main {
  background-color: white;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
